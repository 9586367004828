import React, {useState} from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Alert,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  IdentificationIcon,
  EllipsisHorizontalIcon,
  HomeModernIcon,
  MagnifyingGlassIcon,
  DocumentTextIcon,
  HandThumbUpIcon,
  FolderOpenIcon
} from "@heroicons/react/24/outline";
import {useAlumno} from '../Context/alumnoContext'
import {NavLink} from 'react-router-dom';

export function MenuSidebar({ displaySidebar }) {
  const [open, setOpen] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [hoveredIcon, setHoveredIcon] = useState('');
  const { infoObrero, setCurrentMenu, activeMenu, ocultarMenu } = useAlumno()

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleMouseEnter = (id) => {
    setHoveredIcon(id);
    ocultarMenu();
  };

  const handleMouseLeave = () => {
    setHoveredIcon("");
  };

  return (
    <Card className={`min-h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 !rounded-none 
      ${displaySidebar ? 'flex' : 'hidden lg:flex'}`}>
      <div className="flex flex-col lg:hidden mb-[-15px] p-4">
        <Typography variant="h5" className="text-[#05AFF4]">
          Bienvenido
        </Typography>
        <Typography variant="h6" color="blue-gray">
          {infoObrero.sexo=='M' ? ` ${infoObrero.nombre}` : ` ${infoObrero.nombre}`}
        </Typography>
      </div>
      <List>
        <hr className="my-2 border-blue-gray-50 flex lg:hidden" />
        <Alert color="blue" className="hidden">
          <Typography color="white" className="text-[13px] font-medium leading-[16px]">
            Asociada a la iglesia Centro Familiar Cristiano Vida Abundante 
            (Stolarczuk Lidia Asef/Stolarczuk Jorge Ezequiel)
          </Typography>
        </Alert>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type1' ? '!bg-blue-gray-50' : ''}`}
          onClick={() => { setCurrentMenu('type1'); }}
          onMouseEnter={() => { handleMouseEnter("type1"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/credencial">
            <div className="flex flex-row">
              <ListItemPrefix>
                <IdentificationIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Credencial</p>
            </div>
            <div className={`${hoveredIcon === 'type1' || activeMenu === 'type1' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a su credencial digital
            </div>
          </NavLink>

        </ListItem>
        <ListItem 
          className={`block flex-col justify-start ${activeMenu === 'type2' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type2'); }}
          onMouseEnter={() => { handleMouseEnter("type2"); }}
          onMouseLeave={handleMouseLeave}

        >
          <NavLink to="/info">
            <div className="flex flex-row">
              <ListItemPrefix>
                <UserCircleIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Datos personales</p>
            </div>
            <div className={`${hoveredIcon === 'type2' || activeMenu === 'type2' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a sus datos personales y actualice la información
            </div>
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type3' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type3'); }}
          onMouseEnter={() => { handleMouseEnter("type3"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/password">
            <div className="flex flex-row">
              <ListItemPrefix>
                <EllipsisHorizontalIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Código de seguridad</p>
            </div>
            <div className={`${hoveredIcon === 'type3' || activeMenu === 'type3' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Cambie su código de seguridad
            </div>
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type4' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type4'); }}
          onMouseEnter={() => { handleMouseEnter("type4"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/listado/iglesias">
            <div className="flex flex-row">
              <ListItemPrefix>
                <HomeModernIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Iglesias y Ministerios</p>
            </div>
            <div className={`${hoveredIcon === 'type4' || activeMenu === 'type4' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a los datos de las iglesias o ministerios a su cargo y actualice la información
            </div>
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type5' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type5'); }}
          onMouseEnter={() => { handleMouseEnter("type5"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/nomina">
            <div className="flex flex-row">
              <ListItemPrefix>
                <MagnifyingGlassIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Nóminas</p>
            </div>
            <div className={`${hoveredIcon === 'type5' || activeMenu === 'type5' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Realice búsquedas de ministros e iglesias. Gestione sus ministros y ministerios dependientes
            </div>
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type6' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type6'); }}
          onMouseEnter={() => { handleMouseEnter("type6"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/documentos">
            <div className="flex flex-row">
              <ListItemPrefix>
                <DocumentTextIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Documentos</p>
            </div>
            <div className={`${hoveredIcon === 'type6' || activeMenu === 'type6' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Acceda a diferentes documentos de la asociación
            </div>
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type7' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type7'); }}
          onMouseEnter={() => { handleMouseEnter("type7"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/compromiso">
            <div className="flex flex-row">
              <ListItemPrefix>
                <HandThumbUpIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Compromiso Ministerial</p>
            </div>
            <div className={`${hoveredIcon === 'type7' || activeMenu === 'type7' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Lea y confirme el compromiso ministerial
            </div> 
          </NavLink>
        </ListItem>
        <ListItem
          className={`block flex-col justify-start ${activeMenu === 'type8' && 'bg-blue-gray-50'}`}
          onClick={() => { setCurrentMenu('type8'); }}
          onMouseEnter={() => { handleMouseEnter("type8"); }}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink to="/tramites">
            <div className="flex flex-row">
              <ListItemPrefix>
                <FolderOpenIcon className="relative top-[-2px] h-6 w-6 text-[#111]" />
              </ListItemPrefix>
              <p className="font-semibold">Trámites</p>
            </div>
            <div className={`${hoveredIcon === 'type8' || activeMenu === 'type8' ? 'flex' : 'hidden'} text-[15px] text-[#0595D0] pt-2 px-3`}>
              Realice gestiones administrativas dentro de la asociación
            </div>
          </NavLink>
        </ListItem>
      </List>
      
      <Alert open={openAlert} className="mt-auto">
        <div onClick={() => { window.open('https://wa.me/5491126188236', '_blank'); }} className="cursor-pointer w-full"> 
          <img 
            src="/whatsapp-green.png" 
            className="relative left-[-12px] mt-[-15px] mb-[-10px] cursor-pointer hover:opacity-85 w-[50px] xl:w-[100px]" 
            alt="Whatsapp" 
          />
          <Typography variant="h6" className="mb-1 text-[#25d366]">
            Whatsapp
          </Typography>
          <Typography variant="small" className="font-normal">
            <span className="text-white opacity-80 text-[12px] xl:text-[14px]">Contáctate con nosotros por cualquier consulta o duda que tengas</span>
          </Typography>
        </div>
      </Alert>
    </Card>
  );
}