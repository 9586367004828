/*import React,{useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertDialog = ({abrir,handleClose,title,body,children})=> {

  return (
    <div>
      <Dialog
        open={abrir}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent sx = {{minWidth:'280px'}}
>
          <DialogContentText id="alert-dialog-description">
           {body}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}>No</Button>
          <Button onClick={()=>handleClose(true)} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

*/

import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";

export const AlertDialog = ({open,procesarResultado,titulo,children,texto,maxWidth,procesarCancelar,error,fullWidth,style,style2,backdropProps})=>{  
    return     <div>
    <Dialog BackdropProps={backdropProps}  maxWidth={maxWidth} style={style} fullWidth={fullWidth || false } open={open} onClose={procesarResultado || procesarCancelar}>
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent style={style2}>
        <DialogContentText>
            {texto}
        </DialogContentText>
        {children}
        {error && <p style={{color:'red'}}>{error}</p>}
      </DialogContent>
      <DialogActions>
        {procesarCancelar && <Button color='error' onClick={()=>procesarCancelar(false)}>Cancelar</Button>}
        {procesarResultado && <Button color='primary' variant='contained' onClick={()=>procesarResultado(true)}>Aceptar</Button>}
      </DialogActions>
    </Dialog>
  </div>
}