import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faChevronCircleLeft, faMapMarkerAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import {Link,useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import AbmObrero from '../abms/Abm-obrero';
import AbmIglesia from '../abms/Abm-iglesia';
import Personal from '../Vistas/Personal';
import Credencial from './Credencial';
import Logs from './Logs';
import Eventos from './Eventos';
import {Tramites} from './Tramites';
import Nomina from './Nomina';
import Documentos from './Documentos';
import {useAlumno} from '../Context/alumnoContext';
import Logo from './Logo'
import Qr from './Qr';
import Compromiso from './Compromiso';
import {CodigoSeguridad} from './CodigoSeguridad';

export default function ContenedorVistas ({usuario,tipo}){

const [vistaCargada, setVistaCargada] = useState(false)
const {cargandoVista,ocultarMenu,infoObrero,esCelular} = useAlumno()

//   const history = useHistory();
   const navigate = useNavigate();
   const parametros = useParams();

   useEffect(()=>{
        ocultarMenu()
   },[])

   const volver = ()=>{
        navigate(rutaDestino(tipo))
   }

    if(usuario?.resetpass){
        return   <CodigoSeguridad usuario={usuario}
        retorno = {volver}
        />  
    }

    return (
        <div className="w-cred !bg-transparent pt-6">
            
            {/*tipo!='credencial' && ! esCelular && <div onClick={irHome} className="flex justify-center items-center mt-2 cursor-pointer">
                <Logo width={"50"}/>
            </div>*/}
            {tipo=="obrero" && <AbmObrero 
                id_obrero = {usuario.id_obrero}
                esModal = {false}
                usuario = {usuario}
//                finalizarAltaOcopia = {()=>history.push('/home')}
                finalizarAltaOcopia = {()=>navigate('/home')}
            />}
             {tipo=="iglesia" && 
                         <AbmIglesia id_iglesia={parametros.id_iglesia} 
//                            finalizarAltaOcopia={()=>history.push('/listado/iglesias')}
                            finalizarAltaOcopia={()=>navigate('/listado/iglesias')}
                            esModal={false} usuario={usuario}/>  
             }
             {tipo=="listadoigl" && 
                         <Personal 
                         />  
             }
             {tipo=="credencial" && 
                         <Credencial usuario={usuario} infoObrero={infoObrero}
                         />  
             }
             {tipo=="logs" && 
                         <Logs/>  
             }
             {tipo=="qr" && 
                         <Qr usuario={usuario}
                         />  
             }             
             {tipo=="nomina" && 
                         <Nomina usuario={usuario}
                         />  
             }  
            {tipo=="documentos" && 
                         <Documentos usuario={usuario}
                         />  
             } 
            {tipo=="password" && 
                         <CodigoSeguridad usuario={usuario}
                         retorno = {volver}
                         />  
             } 
             {tipo=="compromiso" && 
                         <Compromiso usuario={usuario}
                         />  
             }  
             {/*El COMPONENTE Eventos hace pinchar el login en los iphone y ipads*/}
             {tipo=="eventos" && 
                         <Tramites usuario={usuario}
                         />  
            }                         
             {/*!cargandoVista && <div onClick={volver} className="cursor-pointer mt-4 mb-4 text-center flex f-row justify-center items-center">
                <FontAwesomeIcon icon={faChevronCircleLeft} 
                                    className=" text-center mt-4 mb-4 text-xxlarge color-tomato" 
                                    title="Volver"
                                    /> <span className="text-small ml-4">Volver</span>
            </div>*/}

        </div>
    )
}


function titulo(tipo){

    switch(tipo){
        case 'nomina':
            return 'Nómina de ministros e iglesias';
        case 'obrero':
            return 'Editar mis datos';
        case 'credencial':
            return 'Credencial digital'; 
        case 'password':
                return 'Cambie su código de seguridad';                
        case 'qr':
            return 'QR';      
        case 'eventos':
            return 'Eventos';  
        case 'documentos':
            return 'Documentos';  
        case 'compromiso':
                return 'Compromiso Ministerial';                                                      
        case 'listadoigl':
            return 'Iglesias a mi nombre';
        case 'logs':
                return 'Logs';           
        case 'iglesia':
            return 'Editar los datos de la iglesia';
    }

}



function rutaDestino(tipo){

    switch(tipo){
        case 'obrero':
        case 'listadoigl':
        case 'credencial':
        case 'qr':
        case 'nomina':
        case 'eventos':
        case 'documentos':
        case 'compromiso':
        case 'password':
        case 'logs':
            return '/home';
        case 'iglesia':
            return '/listado/iglesias';
    }


}