
import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import { Card, CardHeader, Spinner } from "@material-tailwind/react";
import Main from './Main';
import Qr from './Qr';
import { useNavigate } from 'react-router-dom';
import {Alert,Grid,Button,Box} from '@mui/material'
import {Alerta} from '../componentes/Alerta'
import {gql,useQuery} from '@apollo/client'
import { traerUrl,esFotoBase64 } from '../Helpers/utilidades-globales';

export default function Credencial({usuario,infoObrero}){

const [datos,setDatos] = useState([])
const [status,setStatus] = useState(null)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [verQr,setVerQr] = useState(false)
const [esFotoBase64,setEsFotoBase64] = useState(null)
const [parametros,setParametros] = useState({})
const [statuscmin,setStatuscmin] = useState(null)
const navigate = useNavigate()
const [mostrarAlertaCompromiso,setMostrarAlertaCompromiso] = useState(true) // por el conclave lo ponemos en false

const GET_LISTADO = gql`
  query {
    listado {
      id,
      tipo,
      ministro{
        nombre
      }
    }
  }
`;

const {loading,error,data} = useQuery(GET_LISTADO)

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/uad/ministro/foto/${usuario.id_obrero}`),
            Axios.get(`/api/tablasgenerales/credencial/${usuario.id_obrero}`),
            Axios.get('/api/tablasgenerales/parametros'),
            Axios.get(`/api/tablasgenerales/statuscmin/${usuario.id_obrero}`)]) 

            // atención infoObrero.compromiso_ministerial_ok también dice el status del compromiso ministerial
            if(vectorResultado[0].data?.foto){
                setDatos(vectorResultado[0].data?.foto)
                setEsFotoBase64(true)
            }else if (vectorResultado[0].data?.foto4x4){
                const fotoaux = await traerUrl(vectorResultado[0].data?.foto4x4)
                setDatos(fotoaux)
                setEsFotoBase64(false)
            }else{
                setDatos(null)
                setEsFotoBase64(null)
            }

            setStatus(vectorResultado[1].data.status)
            setParametros(vectorResultado[2].data)
            setStatuscmin(vectorResultado[3].data)
            setBuscandoDatos(false)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()               
},[])


    if (buscandoDatos || !statuscmin){
        return (
            <Main center alinear>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                    <Spinner className="h-12 w-12" />
                    <span className="cargando font-semibold text-[15px]">Buscando datos...</span>
                </div>
            </Main>
        )
    };   

    /*if(loading){
        return <p>loading</p>
    }

    if(error){
        return <p>{error?.message || 'error'}</p>
    }

    return <div>
        {data.listado.map(item=><p>
            {item.id} / {item.tipo} / {item.ministro.nombre}
        </p>)}
    </div>
    */
   
/*
    if(mostrarAlertaCompromiso)
    {
        return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={3}>
            <Alert variant="filled" severity="warning"
            onClose={()=>{setMostrarAlertaCompromiso(false)}}
          
            >
                A partir del 01/08/2022 debe confirmar el compromiso ministerial para utilizar la credencial
            </Alert>
            <Button onClick={()=>{history.push('/compromiso')}} color="primary" size="small">
                  Ir al compromiso ministerial
            </Button>
        </Grid>   
      </Grid> 
    }
*/
    
    if(parametros.compromiso_min_obligatorio && (!statuscmin.id_ultimo_cm_aceptado || (Number(statuscmin.id_ultimo_cm_aceptado)<Number(statuscmin.id_ultimo_cm))))
    {
        return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={3}>
            <Alert variant="filled" severity="warning">
               {`Para utilizar la credencial debe aceptar primero el compromiso ministerial`}
            </Alert>
        </Grid>   
        <Button onClick={()=>{navigate('/compromiso')}} color="primary" size="small" className='m-auto mt-2'>
            Ir al compromiso ministerial
        </Button>
      </Grid> 
    }

    if (!status){
        return (
            <div className="credencial-scre acred-cond">
                <div className="cont-cred-scre">
                    <div className="p-2 border-bottom-solid relative">
                        <img src="/logouad.png" className="centrado-scre" alt="Unión de las asambleas de Dios" width="100"/>
                        
                        <div className="div-1-scre mt-1-scre">
                            <span>Registro de Culto 14</span>
                            <span>Pers. Jurídica Nacional 37567</span>
                        </div>
                    </div>

                    <div className="div-1-scre mt-6 mb-6">
                        <span className="ff-ss color-red c-vig-scre">CREDENCIAL INHABILITADA</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Card className="credencial-scre acred-cond mt-4 !bg-white overflow-hidden">
            <CardHeader floated={false} className="flex relative top-[-17px] pt-4 pb-4 justify-center items-center bg-[#ddd]/40 w-full rounded-none">
                {esFotoBase64 && <img className="foto-scre cursor-pointer" width="160" src={`data:image/jpeg;base64,${datos}`} />}
                {!esFotoBase64 && <img className="foto-scre cursor-pointer" width="160" src={datos} />}
            </CardHeader>
            {mostrarAlertaCompromiso && !infoObrero.compromiso_ministerial_ok && !parametros.compromiso_min_obligatorio && 
            <Box sx={{position:'absolute',
                        zIndex:1000,
                        left:0,
                        right:0,
                        height:'30%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        background:'orange',
                        opacity:'0.9'
                        }}>
                <Alerta close={setMostrarAlertaCompromiso}  style={{marginTop:'4rem'}} iraCompromiso={()=>{navigate('/compromiso')}}/>
            </Box>
            }
            <div className="cont-cred-scre relative !bg-white mt-[-16px] !rounded-none !w-full">
                {false && <div className="p-2 border-bottom-solid relative">
                    <img src="/logouad.png" className="centrado-scre" alt="Unión de las asambleas de Dios" width="100"/>
                    <div className="div-1-scre mt-1-scre">
                        <span>Registro de Culto 14</span>
                        <span>Pers. Jurídica Nacional 37567</span>
                    </div>
                    <span className="ff-ss bg-tomato text-white p-2 c-vig-scre">Vigencia {obtenerTextoMesAnioActual()}</span>
                </div>}
                {/*parametros.dia_asamblea==1 && <Qr usuario={usuario} ocultar={()=>setVerQr(false)}/>*/}
                <div className="div-1-scre mt-2">
                        <span className="ncred-scre font-bold text-[#111]">{usuario.nombre.replace(',','').toUpperCase()}</span>
                        <span className='text-[#111] font-semibold'>DNI {usuario.dni}</span>
                    </div>
                    <div className="div-1-scre mt-1-scre">
                        <span>{usuario.rango}</span>
                        <span>Región {usuario.id_region}</span>
                    </div>

                    {/*!verQr && <img className="foto-scre cursor-pointer" onClick={()=>setVerQr(true)} width="180" src={`data:image/jpeg;base64,${datos}`} />*/}
                    <p className="text-small">REF-{usuario.dni}-{usuario.id_obrero}</p>
                    {true && <Qr usuario={usuario} ocultar={()=>setVerQr(false)}/>}
                    {/*parametros.dia_asamblea==1 && <span className="ff-ss color-red c-vig-scre">Vigencia {obtenerTextoMesAnioActual()}</span>*/}
                    {true && <span className="ff-ss text-white bg-[#EE5B07] p-2 c-vig-scre font-medium rounded-md">Vigencia {obtenerTextoMesAnioActual()}</span>}
            </div>
            {/*<button title={verQr ? 'Cerrar el código QR' : 'Ver el código QR'} className="mt-4 mb-2" onClick={()=>setVerQr(!verQr)}><FontAwesomeIcon icon={verQr ? faTimes : faQrcode} className={verQr ? "color-red" : ""}/><p>{verQr ? 'Cerrar el código QR' : 'Ver el código QR'}</p></button>*/}
            
            {/*verQr && <Qr usuario={usuario} ocultar={()=>setVerQr(false)}/>*/}

        </Card>
    )



}


function obtenerTextoMesAnioActual() {
    const fechaActual = new Date();

    const mesActual = fechaActual.getMonth();
    const anioActual = fechaActual.getFullYear();

    switch(mesActual){
        case 0: return `Enero ${anioActual}`;
        case 1: return `Febrero ${anioActual}`;
        case 2: return `Marzo ${anioActual}`;
        case 3: return `Abril ${anioActual}`;
        case 4: return `Mayo ${anioActual}`;
        case 5: return `Junio ${anioActual}`;
        case 6: return `Julio ${anioActual}`;
        case 7: return `Agosto ${anioActual}`;
        case 8: return `Septiembre ${anioActual}`;
        case 9: return `Octubre ${anioActual}`;
        case 10: return `Noviembre ${anioActual}`;
        case 11: return `Diciembre ${anioActual}`;
    }
}

/*
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import Logo from '../componentes/Logo'
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';

export default function Credencial ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [token,setToken] = useState(3);
const [idIntervalo, setIdIntervalo]= useState(0)
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista} = useAlumno()

useEffect(()=>{
    const obtenerUrl = async ()=>{

        setBuscandoDatos(true);
        confirmarCargandoVista(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/uad/ministro/qr/${usuario.id_obrero}`),
                                    Axios.get(`/api/tablasgenerales/credencial/${usuario.id_obrero}`)]) 
            setUrl(vectorResultado[0].data.url)
            setStatus(vectorResultado[1].data.status)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }
    }

    obtenerUrl()       
    resetIntervalo()

},[])

useEffect(()=>{
    if(token<1){
        clearInterval(idIntervalo)
    }
},[token])

const resetIntervalo = () => {
    setToken(100)
    const id = setInterval(() => {
        setToken(token=>token-1)
    }, 1000);

   setIdIntervalo(id)
}

    if(buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }

    if (!status){
        return <div className="w-cred overflow-hidden cpivot">
        <div className="credencial">
         
         <div className="cont-cred">
             <div className="p-2 border-bottom-solid relative">
                 <Logo width={"100"}/>
                 <div className="flex f-col">
                    <span className="color-red p-4">Su credencial se encuentra desactivada</span>
                </div>
             </div>
         </div>
        </div>
    </div>
    }

    if (token==0){
        return <div className="w-cred overflow-hidden cpivot">
            <div className="credencial">
             
             <div className="cont-cred">
                 <div className="p-2 border-bottom-solid relative">
                     <Logo width={"100"}/>
                     <div className="flex f-col">
                        <span className="color-red">Refrescar credencial</span>
                        <button onClick={resetIntervalo}><FontAwesomeIcon className="color-tomato" icon={faSync}/></button>
                    </div>
                 </div>
             </div>
            </div>
            
        </div>
   
    }

    return (
        <div className="w-cred overflow-hidden cpivot">
                <div className="credencial">
             
                    <div className="cont-cred">
                        <div className="p-2 border-bottom-solid relative">
                            <Logo width={"100"}/>
                            <p className="color-red">Credencial Activa</p>
                            <p id="token" className="text-larger">{token}</p>
                        </div>
                        <p className="ncred">{usuario.nombre.replace(',','').toUpperCase()}</p>
                        <p>{usuario.rango.replace(',','')}</p>
                        <p className="mb-2 mt-2">{`Región ${usuario.id_region}`}</p>
                    </div>
                  
                    {url && 
                        <a href={url}>
                            <Qrcode
                                value={url}
                                size={190}
                                level={"H"}
                                includeMargin={true}
                            />
                        </a>}
                       
                </div>
        </div>
    )
}

*/